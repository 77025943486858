// Need to wrap intl provider with redux so that can change the locale and have it update accordingly

import {connect} from 'react-redux';

import {IntlProvider} from 'react-intl';

function mapStateToProps({locale}) {
  let loc = locale.get('locale');
  loc = loc.length > 1 ? loc.slice(0, 2) : 'en';
  return {
    locale: loc,
    messages: locale.getIn(['copy', locale.get('locale')]).toJS(),
    // Key: loc,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(IntlProvider);
