import React from 'react';
import createReactClass from 'create-react-class';


const HelpPage = createReactClass({
  propTypes: {
  },

  componentWillMount() {
  },

  render() {
    return (<div>
      <p>Help!</p>
    </div>
    );
  },
});

export default HelpPage;
