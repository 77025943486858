import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './LocaleSwitcher.module.scss';

import {List} from 'immutable';

const LocaleSwitcher = createReactClass({
  displayName: 'LocaleSwitcher',
  mixins: [PureRenderMixin],
  propTypes: {
    locales: PropTypes.instanceOf(List).isRequired,
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      open: false,
    };
  },

  componentDidMount() {
    document.body.addEventListener('click', this.clickHandler);
  },

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clickHandler);
  },

  clickHandler(e) {
    let selector = '.' + styles.switcher;
    if (this.state.open && !e.target.closest(selector)) {
      this.setState({open: false});
    }
  },


  toggle() {
    this.setState({open: !this.state.open});
  },

  setLang(lang) {
    return () => {
      this.setState({open: false}); // Close first incase async operation takes a while
      this.props.setLocale(lang);
    };
  },

  render() {
    let {locale, locales} = this.props;
    let fullLocale = locales.find(x => x.get('locale') === locale);
    let otherLocales = locales.filter(x => x.get('locale') !== locale);

    return <div className={styles.switcher}>
      <div onClick={this.toggle} className={styles.current}>
        {fullLocale ? fullLocale.get('labels').get(fullLocale.get('locale')) : 'English'}
        {this.state.open ? <i className='material-icons'>expand_less</i> : <i className='material-icons'>expand_more</i>}
      </div>
      {this.state.open ? <div className={styles.select}>
        <ul>
          {otherLocales.map(o => <li key={o.get('locale')} onClick={this.setLang(o.get('locale'))}>
            {o.get('labels').get(fullLocale.get('locale'))} ({o.get('labels').get(o.get('locale'))})
          </li>).toArray()}
        </ul>
      </div> : null}
    </div>;
  },
});

export default LocaleSwitcher;
