import {SET_LOCALE, SET_ROLES_COPY} from './actions';
import {defaultCopy, languages, regions} from '../locale/generateLocales.js!';
import Immutable from 'immutable';

let initialState = {
  locale: 'en-US',
  copy: {
    'en-US': defaultCopy,
  },
  roles: [],
  possibleLocales: languages,
  initial: true, // Tracks whether this is the first page load (i.e. should window.navigator.language be looked at)
  regions: regions,
};

export default function localeReducer(state = Immutable.fromJS(initialState), action) {
  switch (action.type) {
    case SET_LOCALE: {
      if (action.copy) {
        state = state.setIn(['copy', action.locale], Immutable.fromJS(action.copy));
      }

      return state.set('locale', action.locale).set('initial', false);
    }
    case SET_ROLES_COPY: {
      return state.set('roles', Immutable.fromJS(action.value || {}));
    }
    default: {
      return state;
    }
  }
}
