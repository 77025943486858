export const INVALID_TOKEN = 'INVALID_TOKEN';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';
export const SET_TERMS_OF_USE = 'SET_TERMS_OF_USE';

export const SET_LOCALE = 'SET_LOCALE';
export const SET_ROLES_COPY = 'SET_ROLES_COPY';
export const ADD_ROLES_COPY = 'ADD_ROLES_COPY';
export const DELETE_ROLES_COPY = 'DELETE_ROLES_COPY';

export const SET_TD_DEALER = 'SET_TD_DEALER';

export const SET_USER_DEALER_INFO = 'SET_USER_DEALER_INFO';
export const ENABLE_TRUBLUE_TEST_MODE = 'ENABLE_TRUBLUE_TEST_MODE';

export const SET_TRUBLUE_CONFIG = 'SET_TRUBLUE_CONFIG';