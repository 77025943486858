import React from 'react';
import createReactClass from 'create-react-class';

import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';

const LogoutPage = createReactClass({
  displayName: 'LogoutPage',
  mixins: [PureRenderMixin],
  login() {
    browserHistory.push('/login');
  },

  render() {
    return <div>
      <p>You have been successfully logged out.</p>
      <button className='btn btn-primary' onClick={this.login}>Login Again</button>
    </div>;
  },
});

export default LogoutPage;
