import {SET_TERMS_OF_USE, SET_PRIVACY_POLICY, SET_TD_DEALER, SET_USER_DEALER_INFO, ENABLE_TRUBLUE_TEST_MODE} from './actions.js';

export function setTermsOfUse(val) {
  return {
    type: SET_TERMS_OF_USE,
    value: val,
  };
}

export function setPrivacyPolicy(val) {
  return {
    type: SET_PRIVACY_POLICY,
    value: val,
  };
}

export function setTDDealer(val) {
  return {
    type: SET_TD_DEALER,
    value: val,
  };
}

export function setUserDealerInfo(value) {
  return {
    type: SET_USER_DEALER_INFO,
    value,
  };
}

export function enableTruBlueTestMode() {
  return {type: ENABLE_TRUBLUE_TEST_MODE};
}
