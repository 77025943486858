import React from 'react';
import createReactClass from 'create-react-class';

import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './7-three-bounce.module.scss';

const Loader = createReactClass({
  displayName: 'Loader',
  mixins: [PureRenderMixin],

  render() {
    return <div className={styles['sk-three-bounce']}>
      <div className={styles['sk-child'] + ' ' + styles['sk-bounce1']} />
      <div className={styles['sk-child'] + ' ' + styles['sk-bounce2']} />
      <div className={styles['sk-child'] + ' ' + styles['sk-bounce3']} />
    </div>;
  },
});

export default Loader;
