import createRequest, {methodTypes} from '../helpers/request.js';
import {setRolesCopy} from '../redux/locale';

export function getRoles() {
  return dispatch => {
    // Setting all options to default so cors works
    let req = createRequest(window.useradminAddress + '/api/Copy/Roles', {
      method: methodTypes.get,
      dataType: undefined,
      body: null,
      headers: {},
      cache: true,
      authorize: false,
      cors: false,
    });

    req
      .promise()
      .then(x => x.data)
      .then(roles => {
        dispatch(setRolesCopy(JSON.parse(roles)));
      });

    return req;
  };
}
