import moment from 'moment';
import createRequest from '../helpers/request.js';
import {setTruBlueConfig} from '../redux/trublue.js';

export function getTruBlueConfig() {
  return dispatch => {
    let req = createRequest(window.location.origin + '/api/TruBlueConfig', {dataType: 'get', authorize: true});

    return req.promise().then(response => {
      const data = response.data ? JSON.parse(response.data) : {};
      const config = {
        dsmRegStartDate: data.dsmRegStartDate ? moment(data.dsmRegStartDate) : null,
        dsmRegEndDate: data.dsmRegEndDate ? moment(data.dsmRegEndDate) : null,
        regStartDate: data.regStartDate ? moment(data.regStartDate) : null,
        regEndDate: data.regEndDate ? moment(data.regEndDate) : null,
      };

      dispatch(setTruBlueConfig(config));

      return response.data;
    });
  };
}