import createRequest from '../helpers/request.js';
import {setUserDealerInfo} from 'redux/info';

export function getDealerInformation() {
  return dispatch => {
    let req = createRequest(window.location.origin + '/api/Dealer/GetDealerInformation', {dataType: 'get', authorize: true});

    return req.promise().then(response => {
      const dealerInfo = {
        isPlatinum: response.data.IsPlatinum,
        isDSM: response.data.IsDSM,
        hasTruBlueActive: response.data.HasTruBlueActive,
        dsmNumber: response.data.DSMNumber,
      };

      dispatch(setUserDealerInfo(dealerInfo));

      return response.data;
    });
  };
}

export function searchDealers(text, page, pageSize) {
  let req = createRequest(window.location.origin + '/api/Dealer/SearchDealers?text=' + text + '&page=' + page + '&pageSize=' +
    pageSize, {dataType: 'get', authorize: true});

  return req.promise().then(response => response.data);
}

export function setTruBlueDealer(id, country, rmNumber, rmName, scanner) {
  let req = createRequest(window.location.origin +
    '/api/Dealer/RegisterDealerAsTruBlue?id=' + id + '&country=' +
    country + '&rmNumber=' + rmNumber + '&rmName=' + rmName + '&scanner=' + scanner,
    {dataType: 'post', method: 'POST', authorize: true});

  return req.promise().then(response => response);
}

export function getRMList() {
  let req = createRequest(window.location.origin + '/api/Dealer/RMList', {dataType: 'get', authorize: true});

  return req.promise().then(response => response.data);
}
