import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import PageHeader from 'layout/PageHeader/PageHeader.jsx';
import Dealer from './Dealer/Dealer.jsx';
import {langImageMatcher, langMapMatcher} from 'helpers/translationHelper';
import styles from './Home.module.scss';
import qwest from 'qwest';
import {createThinkificToken, generateThinkificLoginURL} from 'helpers/thinkificAPIHelper';
import {intlShape, injectIntl} from 'react-intl';

const Home = createReactClass({
  displayName: 'Home',
  propTypes: {
    token: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    roles: PropTypes.instanceOf(Map).isRequired,
    intl: intlShape.isRequired,
    dealerInfo: PropTypes.object.isRequired,
    truBlueDates: PropTypes.instanceOf(Map).isRequired,
  },

  getInitialState() {
    return {
      sites: [],
    };
  },

  componentWillMount() {
    let appKeys = [];

    if (this.props.token && this.props.token.profile && this.props.token.profile.app) {
      appKeys = this.props.token.profile.app;
    }

    if (!(appKeys instanceof Array)) {
      appKeys = [appKeys];
    }

    let url = window.useradminAddress + '/api/App/Metadata?appNames=' + encodeURIComponent((appKeys || []).join(','));
    // Need to cache true to elminate cors call since otherwise qwest adds cache control headers

    qwest.get(url, null, {cache: true})
      .then((xhr, res) => res)
      .then(sites => {
        this.setState({sites: sites.data});
      })
      .catch(err => {
        console.error(err);
      });
  },

  render() {
    let appKeys = [];
    const {locale, roles, token, dealerInfo, truBlueDates} = this.props;
    const {profile} = token;

    if (token && profile && profile.app) {
      appKeys = profile.app;
    }

    if (!(appKeys instanceof Array)) {
      appKeys = [appKeys];
    }

    let apps = this.state.sites
      .filter(item => item.url && item.name && item.image && item.name !== window.clientID)
      .map(item => {
        let translations = roles.toJS();


        if (item.name === 'education') {
          //  ALEX-2511 provide H2Know app for all users that have educationapp
          const token = createThinkificToken({firstName: profile.given_name, lastName: profile.family_name, email: profile.email});
          const thinkificURL = generateThinkificLoginURL(token, 'h2know');

          // Wildfire - 2021 Program Support C03 - Hide Education app connected to Bridge  and instead connect Thinkific to the Education app
          return <Dealer
            key='h2know'
            image={langImageMatcher(item.image, item.imageF, locale)} // Use Education app image
            link={thinkificURL}
            appName={this.props.intl.formatMessage({id: 'h2know.app.name'})} // Keep H2Know wording
            copy={langMapMatcher(translations, item.description)[locale] || item.description} />;
          }


        return <Dealer
          key={item.name}
          image={langImageMatcher(item.image, item.imageF, locale)}
          link={item.url}
          copy={langMapMatcher(translations, item.description)[locale] || item.description} />;
      });


    return <div>
      <PageHeader title={this.props.intl.formatMessage({id: 'dashboard.pageHeader'})} visible={false} />
      <div className='row'>
        <div className={styles.dealers + ' col-sm-12'}>
          <div className={styles.spaced + ' row'}>
            {apps.length > 0 ? apps : this.props.intl.formatMessage({id: 'dashboard.no-apps'})}
          </div>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(Home);
