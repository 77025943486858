import {generateHS256JWTToken} from './tokenJWTGenerator';

const THINKIFIC_API_KEY = '1c2d145258cef43655805be7499e1aa4';

export function createThinkificToken({firstName, lastName, email}) {
  var payload = {
    'first_name': firstName,
    'last_name': lastName,
    'email': email,
    'iat': (new Date).getTime() / 1000,
  };

  return generateHS256JWTToken(payload, THINKIFIC_API_KEY);
}

export function generateThinkificLoginURL(token, biolabSchool) {
  // [SSO-21] Change the SSO to send the user to the "mybioguard" domain
  return `https://${biolabSchool}.mybioguard.com/api/sso/v2/sso/jwt?jwt=${token}&return_to=https%3A%2F%2F${biolabSchool}.mybioguard.com/`;
}
