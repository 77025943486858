import Immutable from 'immutable';

import {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from '../helpers/tokenManager.js';
import {actionCreator} from './info.js';
import moment from 'moment';

import {UPDATE_TOKEN, INVALID_TOKEN, SET_TERMS_OF_USE, SET_PRIVACY_POLICY, SET_TD_DEALER, SET_USER_DEALER_INFO,
ENABLE_TRUBLUE_TEST_MODE, SET_TRUBLUE_CONFIG} from './actions.js';

export default function dataReducer(state = Immutable.fromJS({
  user: {},
  info: {},
  dealerInfo: {
    isDSM: false,
    isPlatinum: false,
    hasTruBlueActive: false,
    dsmNumber: undefined,
  },
  truBlueDates: {},
}), action) {
  switch (action.type) {
    case SET_TERMS_OF_USE: {
      return state.setIn(['info', 'termsOfUse'], {__html: action.value});
    }

    case SET_PRIVACY_POLICY: {
      return state.setIn(['info', 'privacyPolicy'], {__html: action.value});
    }

    case SET_USER_DEALER_INFO: {
      return state.set('dealerInfo', Immutable.fromJS(action.value || {}));
    }

    case ENABLE_TRUBLUE_TEST_MODE: {
      return state
        .setIn(['dealerInfo', 'isPlatinum'], true)
        .setIn(['truBlueDates', 'dsmRegStartDate'], moment().add(-1, 'month'))
        .setIn(['truBlueDates', 'dsmRegEndDate'], moment().add(1, 'month'))
        .setIn(['truBlueDates', 'regStartDate'], moment().add(-1, 'month'))
        .setIn(['truBlueDates', 'regEndDate'], moment().add(1, 'month'));
    }

    case SET_TRUBLUE_CONFIG: {
      return state
        .setIn(['truBlueDates', 'dsmRegStartDate'], action.value.dsmRegStartDate)
        .setIn(['truBlueDates', 'dsmRegEndDate'], action.value.dsmRegEndDate)
        .setIn(['truBlueDates', 'regStartDate'], action.value.regStartDate)
        .setIn(['truBlueDates', 'regEndDate'], action.value.regEndDate);
    }

    default: {
      return state;
    }
  }
}

const manager = createTokenManager(createTokenManagerConfig());

export function tokenReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_TOKEN: {
      let newState = {
        idToken: manager['id_token'],
        valid: !manager.expired,
        profile: manager.profile,
      };

      return newState;
    }
    case INVALID_TOKEN: {
      let newState = {
        idToken: null,
        valid: false,
        profile: {},
      };

      return newState;
    }
    default: {
      return state;
    }
  }
}

export const tbDealerReducer = (state = {}, action) => {
  switch(action.type) {
    case SET_TD_DEALER: {
      return {
        ...state,
        dealer: action.value,
      };
    }
    default: {
      return state;
    }
  }
};
