import {connect} from 'react-redux';
import { getRoles } from '../api/copy';
import Home from '../components/pages/Home/Home.jsx';

function mapStateToProps({token, locale, data}) {
  return {
    token,
    dealerInfo: data.get('dealerInfo').toJS(),
    roles: locale.get('roles'),
    locale: locale.get('locale'),
    truBlueDates: data.get('truBlueDates'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoles: () => dispatch(getRoles()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
