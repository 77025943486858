export default function createTokenManagerConfig() {
  let config =  {
    scope: 'openid profile roles email dealer',
    authority: window.idp,
  };

  // Put configs with underscores like this so they don't anger the linter
  config['client_id'] = window.clientID;
  config['redirect_uri'] = window.clientAddress + '/login';
  config['response_type'] = 'id_token';
  config['post_logout_redirect_uri'] = window.clientAddress + '/logout';
  config['filter_protocol_claims'] = false; // Includes exp properly in parsed token so can check if expired or not

  return config;
}
