import Immutable from 'immutable';

import {
  TOGGLE_MODAL, SET_DELETE_STORE, CLEAR_DELETE_STORE,
} from './actions.js';

const initialState = {
  modals: {
    deleteApp: false,
    deleteAttribute: false,
    deleteDealer: false,
    deletePermission: false,
    deleteRole: false,
    deleteStore: false,
    deleteUser: false,
    disclaimer: false,
    privacy: false,
  },
  toasts: {},
};

export default function uiReducer(state = Immutable.fromJS(initialState), action) {
  switch (action.type) {
    case TOGGLE_MODAL: {
      return state.setIn(['modals', action.key], action.value);
    }

    case SET_DELETE_STORE: {
      return state.setIn(['modals', 'deleteStore'], true);
    }

    case CLEAR_DELETE_STORE: {
      return state.setIn(['modals', 'deleteStore'], false);
    }

    default: {
      return state;
    }
  }
}
