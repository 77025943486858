import Footer from '../components/layout/Footer/Footer.jsx';
import {connect} from 'react-redux';
import {getPrivacyPolicy, getTermsOfUse} from '../api/infoApi.js';

import { injectIntl } from 'react-intl';

function mapStateToProps({data, locale}) {
  return {
    locale: locale.get('locale'),
    privacyPolicy: data.getIn(['info', 'privacyPolicy']),
    termsOfUse: data.getIn(['info', 'termsOfUse']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPrivacyPolicy: (...args) => dispatch(getPrivacyPolicy(...args)),
    getTermsOfUse: (...args) => dispatch(getTermsOfUse(...args)),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Footer));
