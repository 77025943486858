// Split into two packages, one for IE with the huge intl polyfills and one without it for everything else
if (!window.Intl) {
  window.isIntlPolyfilled = true;
  require.ensure(['intl', './main.jsx'], function(require) {
    require('intl');
    require('./main.jsx');
  });
} else {
  require('./main.jsx'); // eslint-disable-line global-require
}
