import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from '../../../helpers/tokenManager.js';
import {browserHistory} from 'react-router';
import {List} from 'immutable';
import NavBar from '../NavBar/NavBar.jsx';
import Footer from '../../../containers/Footer.jsx';
import styles from './App.module.scss';
import path from 'path';
import moment from 'moment';


// To enable trublue for testing
const keyCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
const maxDelay = 500;

function createKeyListener(cb) {
  let seq = [];
  let lastTime = undefined;
  let timer = undefined;

  return e => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }

    if (e.key !== keyCode[seq.length] || lastTime && moment().valueOf() - lastTime.valueOf() > maxDelay) {
      seq = [];
      lastTime = undefined;
      return;
    }

    seq.push(e.key);
    lastTime = moment();

    if (seq.length === keyCode.length) {
      seq = [];
      lastTime = undefined;

      cb();
      return;
    }

    timer = setTimeout(() => {
      seq = [];
      lastTime = undefined;
      timer = undefined;
    }, maxDelay);
  };
}

const App = createReactClass({
  displayName: 'App',
  mixins: [PureRenderMixin],
  propTypes: {
    children: PropTypes.any,
    loc: PropTypes.string.isRequired,
    status: PropTypes.object,
    token: PropTypes.object,
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    disclaimerModalVisible: PropTypes.bool.isRequired,
    privacyModalVisible: PropTypes.bool.isRequired,
    toggleDisclaimerModal: PropTypes.func.isRequired,
    togglePrivacyModal: PropTypes.func.isRequired,
    initialLoad: PropTypes.bool.isRequired,
    getRoles: PropTypes.func.isRequired,
    getDealerInformation: PropTypes.func.isRequired,
    enableTruBlueTestMode: PropTypes.func.isRequired,
  },

  componentWillMount() {
    let {locale, possibleLocales, setLocale, initialLoad} = this.props;

    this.props.getRoles();

    this.props.getDealerInformation();

    this.props.getTruBlueConfig();

    let fullLocale = possibleLocales.find(x => x.get('locale') === locale);
    let newLocale = possibleLocales.find(x => x.get('locale') === window.navigator.language || x.get('shortLocale') === window.navigator.language);

    if (initialLoad && fullLocale && newLocale && fullLocale !== newLocale) {
      setLocale(newLocale.get('locale'));
    }
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.token !== nextProps.token || this.props.locale !== nextProps.locale) {
      this.bindAppSelect(nextProps);
    }
  },

  componentDidMount() {
    this.bindAppSelect(this.props);

    this.truBlueKeySeq = createKeyListener(this.props.enableTruBlueTestMode);

    if (this.props.token && this.props.token.profile && this.props.token.profile.locale && this.props.token.profile.locale !== this.props.locale) {
      this.props.setLocale(this.props.token.profile.locale);
    }

    window.addEventListener('keydown', this.truBlueKeySeq);
  },

  componentWillUnmount() {
    window.removeEventListener('keydown', this.truBlueKeySeq);
  },

  bindAppSelect({token, locale}) {
    const {profile} = token;
    let appKeys = [];

    if (token && profile && profile.app) {
      appKeys = profile.app;
    }

    if (!(appKeys instanceof Array)) {
      appKeys = [appKeys];
    }

    window.insertAppSelector(window.useradminAddress, appKeys, window.clientID, this.signOutUser,
      this.signIn, Object.keys(this.props.token).length > 0, false, locale,
      profile && profile.address ? profile.address.country || 'US' : 'US',
      profile && profile.given_name ? profile.given_name : '',
      profile && profile.family_name ? profile.family_name : '',
      profile && profile.email ? profile.email : '');
  },

  hideDisclaimerModal() {
    return this.props.toggleDisclaimerModal(false);
  },

  openDisclaimerModal() {
    return this.props.toggleDisclaimerModal(true);
  },

  hidePrivacyModal() {
    return this.props.togglePrivacyModal(false);
  },

  openPrivacyModal() {
    return this.props.togglePrivacyModal(true);
  },

  signOutUser() {
    // Built in logout function in token manager is broken
    let config = createTokenManagerConfig();
    let manager = createTokenManager(config);
    let idTokenHint = this.props.token.idToken;

    manager.removeToken();

    let logoutPath = 'https://' + path.join(window.idp.replace('https://', ''), '/connect/endsession?id_token_hint=' + encodeURI(idTokenHint) +
      '&post_logout_redirect_uri=' + encodeURI(config['post_logout_redirect_uri']));

    window.location.href = logoutPath;
  },

  signIn() {
    browserHistory.push('/login');
  },
  _setLocal(local) {
    this.props.setLocale(local);
  },

  render() {
    return <div className={styles.wrapper}>
      <NavBar token={this.props.token} title='User Portal' activePage={window.location.hash} locale={this.props.locale}
              setLocale={this._setLocal}
              locales={this.props.possibleLocales}/>
      <div className={styles.mainContent}>
        <div className='container'>
          {this.props.children}
        </div>
      </div>
      <div className={styles.pushFooter}/>
      <Footer
        disclaimerModalVisible={this.props.disclaimerModalVisible}
        hideDisclaimerModal={this.hideDisclaimerModal}
        openDisclaimerModal={this.openDisclaimerModal}
        getPrivacyPolicy={this.getPrivacyPolicy}
        getTermsOfUse={this.getTermsOfUse}
        privacyModalVisible={this.props.privacyModalVisible}
        hidePrivacyModal={this.hidePrivacyModal}
        openPrivacyModal={this.openPrivacyModal}
      />
    </div>;
  },
});

export default App;
