import {connect} from 'react-redux';
import App from '../components/layout/App/App.jsx';
import {toggleModal} from '../redux/modals.js';
import {setLocale} from '../locale/generateLocales.js!';
import {getRoles} from '../api/copy';
import {getDealerInformation} from 'api/dealerApi';
import {getTruBlueConfig} from 'api/truBlueApi';
import {enableTruBlueTestMode} from 'redux/info';

function mapStateToProps({token, data, routing, ui, locale}) {
  return {
    token,
    status: data.get('status'),
    loc: routing && routing.locationBeforeTransitions ? routing.locationBeforeTransitions.pathname || '' : '',
    toasts: ui.get('toasts').toList(),
    initialLoad: locale.get('initial'),
    locale: locale.get('locale'),
    possibleLocales: locale.get('possibleLocales'),
    disclaimerModalVisible: ui.getIn(['modals', 'disclaimer']),
    privacyModalVisible: ui.getIn(['modals', 'privacy']),
  };
}

const toggleDisclaimerModal = toggleModal.bind(null, 'disclaimer');
const togglePrivacyModal = toggleModal.bind(null, 'privacy');

export default connect(mapStateToProps, {
  toggleDisclaimerModal,
  togglePrivacyModal,
  getRoles,
  setLocale,
  getDealerInformation,
  getTruBlueConfig,
  enableTruBlueTestMode,
})(App);
