export  function langMapMatcher(array, data) {
  let translatedDesc = {
      'en-US': null,
      'fr-CA': null,
    };
  array.forEach((x) => {
    for (let key in x) {
      if (!x.hasOwnProperty(key)) {
        continue;
      }

      if (x[key] === data) {
        translatedDesc = x;
      }
    }
  });
  return translatedDesc;
}
// TODO: Need better system. Upload separate french image and store route in db? or Just store original file name and keep path seperate
export function langImageMatcher(img, imgf, lang) {
  if (lang === 'fr-CA' && imgf) {
    return imgf;
  }

  return img;
}
