import React from 'react';
import createReactClass from 'create-react-class';

import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';

const LoginNeeded = createReactClass({
  displayName: 'LoginNeeded',
  mixins: [PureRenderMixin],
  login() {
    setTimeout(() => {
      browserHistory.push('/login');
    }, 700);
  },

  componentDidMount() {
    this.login();
  },

  render() {
    return <div />;
  },
});

export default LoginNeeded;
