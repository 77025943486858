import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {Link} from 'react-router';

import Icon from '../../Icon/Icon.jsx';
import {List} from 'immutable';
import styles from './NavBar.module.scss';
import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

import logo from './logo.png';

function composeCSS(...args) {
  return args.join(' ');
}

const NavBar = createReactClass({
  displayName: 'NavBar',
  mixins: [PureRenderMixin],

  propTypes: {
    activePage: PropTypes.string,
    intl: intlShape.isRequired,
    token: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    locales: PropTypes.instanceOf(List).isRequired,
  },

  setBlueTheme() {
    return window.location.pathname.includes('platinum-trublue-information') ||
      window.location.pathname.includes('register-dealer') ||
      window.location.pathname.includes('registered-dealer') ||
      window.location.pathname.includes('search-dealers');
  },

  getLogo(formatMessage) {
    if(this.setBlueTheme()) {
      return <Link to='/'>
        <img src='/Content/images/TruBlueLogoWhite.svg' height={50} className={styles.logo} title={formatMessage({id: 'site.title'})} />
      </Link>;
    }

    return <Link to='/'>
      <img src={logo} height={60} style={{marginRight: 14}} title={formatMessage({id: 'site.title'})} />
      <h1 className={styles.mainHeader} style={{marginLeft: 0, marginTop: 18, display: 'inline-block'}}><FormattedMessage id='site.title' /></h1>
    </Link>;
  },

  render() {
    let {formatMessage} = this.props.intl;
    let {locales, locale, setLocale} = this.props;

    return <div className={composeCSS('navbar', 'navbar-static-top', styles.navbar, this.setBlueTheme() ? styles.blueNavbar : '')}>
      <div>
        <div className='container'>
          <div className='navbar-header' style={{float: 'left'}}>
            {this.getLogo(formatMessage)}
          </div>
          <div className='pull-right'>
            <LocaleSwitcher locales={locales} locale={locale} setLocale={setLocale} />
            <div id='app-select' className={styles.apps}>
              <button className='btn icon-button'>
                <Icon name='app' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(NavBar);
