import Utf8 from 'crypto-js/enc-utf8';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

function base64url(source) {
    // Encode in classical base64
  var encodedSource = Base64.stringify(source);

    // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
}

export function generateHS256JWTToken(payload, key) {
  var header = {
    'alg': 'HS256',
    'typ': 'JWT',
  };
  var stringifiedHeader = Utf8.parse(JSON.stringify(header));
  var encodedHeader = base64url(stringifiedHeader);
  var stringifiedData = Utf8.parse(JSON.stringify(payload));
  var encodedData = base64url(stringifiedData);
  var signature = encodedHeader + '.' + encodedData;

  signature = hmacSHA256(signature, key);
  signature = base64url(signature);

  return `${encodedHeader}.${encodedData}.${signature}`;
}

