import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/main.module.scss';

import React from 'react';

import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import dataReducer, {tokenReducer, tbDealerReducer} from './redux/reducers.js';
import uiReducer from './redux/uiReducer.js';
import localeReducer from './redux/localeReducer';
import {Router, Route, browserHistory, IndexRoute} from 'react-router';
import {syncHistoryWithStore, routerReducer, routerMiddleware, LOCATION_CHANGE} from 'react-router-redux';
import thunk from 'redux-thunk';

import IntlProvider from './containers/IntlProvider.jsx';

// IE sucks and doesn't have Object.assign :(
if (typeof Object.assign !== 'function') {
  Object.assign = function(target) { // .length of function is 2
    if (target === null || target === undefined) { // TypeError if undefined or null
      throw new TypeError('Cannot convert undefined or null to object');
    }

    var to = Object(target);

    for (let index = 1; index < arguments.length; index++) {
      var nextSource = arguments[index];

      if (nextSource !== null || nextSource === undefined) { // Skip over if undefined or null
        for (let nextKey in nextSource) {
          // Avoid bugs when hasOwnProperty is shadowed
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
    return to;
  };
}

// Manage openid connect tokens and integrate with redux
import createTokenMiddleware, {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from './helpers/tokenManager.js';

import {invalidateToken} from './redux/tokens.js';

import App from './containers/App.jsx';

// Pages to route
import Home from './containers/Home.jsx';
import LoginNeeded from './components/pages/Login/LoginNeeded.jsx';
import LogoutPage from './components/pages/Logout/Logout.jsx';
import LoginPage from './components/pages/Login/Login.jsx';
import HelpPage from './components/pages/Help/Help.jsx';

require('element-closest'); // Element.closest polyfill for IE

let initialState = {};
const manager = createTokenManager(createTokenManagerConfig());

if (!manager.expired) {
  initialState = {
    token: {
      idToken: manager['id_token'],
      valid: !manager.expired,
      profile: manager.profile,
    },
  };
}

// Block token from trying to validate on login related routes so that don't get stuck in login loop
let blockedRoutes = [
  'login',
  'logout',
  'login-needed',
  '/login',
  '/logout',
  '/login-needed',
];

function validateToken(state, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (blockedRoutes.indexOf(action.payload) !== -1 || blockedRoutes.indexOf(action.payload.pathname) !== -1) {
        return false;
      }
      return true;
    }
    default: {
      return true;
    }
  }
}

const store = createStore(
  combineReducers({
    token: tokenReducer,
    routing: routerReducer,
    locale: localeReducer,
    data: dataReducer,
    ui: uiReducer,
    tbDealer: tbDealerReducer,
  }),
  initialState,
  compose(
    applyMiddleware(createTokenMiddleware(createTokenManagerConfig(), validateToken, invalidateToken())),
    applyMiddleware(routerMiddleware(browserHistory)),
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

const history = syncHistoryWithStore(browserHistory, store);

// Function to require authentication on a router. Use onEnter={requireAuth} hook to force logged in.
function requireAuth(nextState, replace) {
  if (!manager['id_token'] || manager.expired) {
    replace({
      pathname: '/login-needed',
      state: {nextPathname: nextState.location.pathname},
    });
  }
}

render(
  <Provider store={store}>
    <IntlProvider>
      <Router history={history}>
        <Route path='/' component={App}>
          <IndexRoute component={Home} onEnter={requireAuth} />
          <Route path='login-needed' component={LoginNeeded} />
          <Route path='login' component={LoginPage} />
          <Route path='logout' component={LogoutPage} />
          <Route path='help' component={HelpPage} />
        </Route>
      </Router>
    </IntlProvider>
  </Provider>,
  document.getElementById('app-container')
);
