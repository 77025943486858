import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import styles from './Dealer.module.scss';

const Dealer = createReactClass({
  displayName: 'Dealer',
  mixins: [PureRenderMixin],
  propTypes: {
    copy: PropTypes.string,
    hideDisclaimerModal: PropTypes.func.isRequired,
    hidePrivacyModal: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    link: PropTypes.string,
    appName: PropTypes.string,
  },

  getDefaultProps() {
    return {
      copy: '',
      link: '#',
    };
  },

  hideModals() {
    this.props.hideDisclaimerModal();
    this.props.hidePrivacyModal();
  },

  render() {
    return (
      <div className="col-sm-4">
        <div className={styles.dealer}>
          <a href={this.props.link}>
            <div className={styles.image}>
              {this.props.image ? <img src={this.props.image} alt="" /> : null}
              {this.props.appName && !this.props.image ? <h2 className={styles.name}>{this.props.appName}</h2> : null}
            </div>
            <p>{this.props.copy}</p>
          </a>
        </div>
      </div>
    );
  },
});

export default Dealer;
