import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {CallbackComponent, triggerAuthFlow} from 'redux-oidc';
import createTokenManagerConfig from '../../../helpers/tokenManager.js';
import {browserHistory} from 'react-router';

import Loader from '../../misc/Loader/Loader.jsx';

const LoginPage = createReactClass({
  propTypes: {
    token: PropTypes.object,
  },

  componentWillMount() {
    if (this.props.token && this.props.token.idToken && this.props.token.valid) {
      setTimeout(() => {
        browserHistory.push('/');
      }, 500);
    }
  },

  // This method gets called when the token validation fails and triggers auth flow
  onTokenValidationError(error) {
    console.log('Error getting token:', error);
    triggerAuthFlow(createTokenManagerConfig());
  },

  // Pass in custom content to render in the CallbackComponent
  customContent() {
    return <Loader />;
  },

  render() {
    return (<div>
      <CallbackComponent config={createTokenManagerConfig()} errorCallback={this.onTokenValidationError} redirectOnSuccess={true} redirectUri='/'>
        { this.customContent() }
      </CallbackComponent>
    </div>
    );
  },
});

export default LoginPage;
